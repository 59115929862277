<template>
  <div>
    <section class="section">
      <h3 class="is-size-3 has-font-alternate has-margin-bottom">Overview</h3>
      <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
    </section>
    <section class="section">
      <h3 class="is-size-3 has-font-alternate has-margin-bottom">By Service</h3>
      <div class="container">
        <div v-if="salesTableData">
          <div v-if="salesTableData.length > 0">
            <el-switch class="has-small-margin-right" v-model="viewingTestData"></el-switch>
            <span v-if="viewingTestData" class="is-size-6-7">Viewing Test Data</span>
            <span v-else class="is-size-6-7">View Test Data</span>
            <el-table
              :data="salesTableData"
              :default-sort="{prop: 'orderCount', order: 'descending'}"
            >
              <el-table-column label="Service">
                <template slot-scope="scope">
                  <span class="has-small-margin-right">{{ scope.row.service }}</span>
                  <el-tag size="mini" v-show="scope.row.isTest">Test</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="orderCount"
                label="# Orders"
                :formatter="formatOrderCount"
                sortable
              ></el-table-column>
              <el-table-column prop="totalSales" label="Sales" :formatter="formatSales" sortable></el-table-column>
              <el-table-column label="Charts"></el-table-column>
            </el-table>
          </div>
          <div v-else>Looks like you don't have any requisitions yet</div>
        </div>
        <div v-else>Loading...</div>
      </div>
    </section>
  </div>
</template>


<script>
import { API, graphqlOperation, Auth } from 'aws-amplify'
import axios from 'axios'
import { listRequisitions } from '@/graphql/queries'
import { STATUS } from '@/graph-constants'
import { vendorRetryEndpoint } from '@/api/requisitions'
import { timeFormat } from 'd3'
import VueApexCharts from 'vue-apexcharts'

const requisitionDateTimeFormat = timeFormat('%b %d at %-I:%M %p') // TODO: lift it into another file

const requisitionEntryMap = (requisition) => ({
  service: requisition.service.title,
  date: new Date(requisition.createdAt),
  amount: requisition.amount,
  status: requisition.status[requisition.status.length - 1].value, // TODO create utility for this
  id: requisition.id,
  isTest: requisition.vendor === requisition.owner,
})

function groupRequisitionsOnService(reqs) {
  const serviceRequisitionMap = new Map()
  reqs.forEach((req) => {
    const { service } = req
    const reqsPerService = serviceRequisitionMap.get(service)
    if (!reqsPerService) {
      serviceRequisitionMap.set(service, [req])
    } else {
      reqsPerService.push(req)
    }
  })
  return serviceRequisitionMap
}

export default {
  name: 'Analytics',
  components: { apexchart: VueApexCharts },
  data() {
    return {
      salesTableData: null,
      viewingTestData: false,
      options: {
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: 'series-1',
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    }
  },
  async created() {
    const res = await API.graphql(
      graphqlOperation(listRequisitions, {
        limit: 1000, // TODO: revisit
        filter: {
          vendor: { eq: this.$store.state.user.username },
        },
      })
    )
    this.requisitions = res.data.listRequisitions.items.map(requisitionEntryMap)
    this.salesTableData = this.getSalesTableData()
  },
  computed: {},
  methods: {
    getFilteredRequisitions(filters) {
      return this.requisitions.filter(filters)
    },

    getSalesTableData() {
      const filterForTestFlagAndFulfilled = (requisitionEntry) =>
        requisitionEntry.isTest === this.viewingTestData && STATUS.FULFILLED === requisitionEntry.status

      const filteredRequisitions = this.getFilteredRequisitions(filterForTestFlagAndFulfilled)

      const groupedRequisitions = groupRequisitionsOnService(filteredRequisitions)

      const tableRows = []
      const sumSales = (total, currentRecord) => total + currentRecord.amount
      // eslint-disable-next-line
      for (const [service, requisitions] of groupedRequisitions) {
        const row = {
          service,
          orderCount: requisitions.length,
          totalSales: requisitions.reduce(sumSales, 0),
        }
        tableRows.push(row)
      }
      return tableRows
    },

    formatDate(row, col, cellValue) {
      return requisitionDateTimeFormat(cellValue)
    },

    formatOrderCount(row, col, cellValue) {
      return cellValue // TODO: revisit
    },

    formatSales(row, col, cellValue) {
      return `$${(cellValue / 100).toFixed(2)}`
    },

    formatStatus(row, col, cellValue) {
      const map = {
        [STATUS.CREATED]: 'Order initiated',
        [STATUS.RECEIVED]: 'Order received',
        [STATUS.PROCESSING]: 'Order processing',
        [STATUS.REJECTED]: 'Rejected',
        [STATUS.NOT_RECEIVED]: 'Not Accepted',
        [STATUS.FULFILLED]: 'Fulfilled',
      }
      return map[cellValue] || ''
    },
    async retry(id, row) {
      row.retrying = true
      try {
        const session = await Auth.currentSession()
        const headers = {
          Authorization: session.idToken.jwtToken,
        }
        await axios.post(vendorRetryEndpoint, { id }, { headers })
        this.$message({
          message: 'Retrying order request',
        })
        row.retrying = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap-word {
  word-break: break-word;
}
</style>

